<template>
  <v-container>
    <template>
      <v-data-table
        :headers="headers"
        :items="emails"
        class="elevation-5 custom-table"
        :no-data-text="$t('no_data')"
        :search="search"
        :loading="loading"
        mobile-breakpoint="0"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title
              ><v-text-field
                v-model="search"
                append-icon="search"
                :label="$t('search_txt')"
                single-line
                hide-details
                filled
                dense
                rounded
                class="mx-auto"
                style="max-width: 500px"
                clearable
              ></v-text-field
            ></v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn color="primary" class="mb-2" to="/email">
              {{ $t("new_email") }}
            </v-btn>
            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title
                  class="headline"
                  v-text="$t('delete_email_confirm')"
                />
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialogDelete = false">
                    {{ $t("cancel") }}
                  </v-btn>
                  <v-btn color="primary" @click="deleteEmail()">
                    {{ $t("delete") }}
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:[`item.created_at`]="{ item }">
          {{ item.created_at | moment }}
        </template>
        <template v-slot:[`item.updated_at`]="{ item }">
          {{ item.updated_at | moment }}
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn icon @click="showEmail(item)">
            <v-icon>remove_red_eye</v-icon>
          </v-btn>
          <v-btn color="primary" icon class="mr-2" :to="'/email/' + item.id">
            <v-icon>edit</v-icon>
          </v-btn>

          <v-btn color="red darken-3" @click="initDelete(item)" icon>
            <v-icon>delete</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </template>
    <v-dialog v-model="dialogShow" width="700">
      <v-card>
        <v-card-text
          style="white-space: pre-wrap; padding-top: 20px; color: black"
        >
          <iframe
            onload='(function(o){o.style.height=o.contentWindow.document.body.scrollHeight+"px";}(this));'
            style="height: 200px; width: 100%; border: none; overflow: hidden"
            :srcdoc="content.html"
          ></iframe>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialogShow = false">
            {{ $t("ok") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { email } from "@/api/URLs";
import { getEmailsH } from "@/api/headers";

export default {
  name: "Emails",
  data() {
    return {
      content: {
        email: null,
        subject: null,
        html: null,
        design: null,
      },
      emails: [],
      loading: false,
      dialogDelete: false,
      tempItem: null,
      headers: getEmailsH(),
      search: null,
      dialogShow: false,
    };
  },
  watch: {
    "$root.$i18n.locale": function () {
      this.headers = getEmailsH();
    },
  },
  created() {
    this.getAll();
  },
  methods: {
    getAll() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "get",
        url: email,
      }).then(function (response) {
        if (response.data.success) self.emails = response.data.data;
        console.log(response);
        self.loading = false;
      });
    },
    initDelete(item) {
      this.tempItem = Object.assign({}, item);
      this.tempItem.index = this.emails.indexOf(item);
      this.dialogDelete = true;
    },
    deleteEmail() {
      this.loading = true;
      let self = this;
      this.$http({
        method: "delete",
        url: email + "/" + self.tempItem.id,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.dialogDelete = false;
          self.emails.splice(self.tempItem.index, 1);
        }
        self.loading = false;
      });
    },
    showEmail(item) {
      this.getEmail(item);
    },
    getEmail(item) {
      let self = this;
      this.$http({
        method: "get",
        url: email + "/" + item.id,
      }).then(function (response) {
        console.log(response);
        if (response.data.success) {
          self.content = response.data.data;
          self.dialogShow = true;
        }
      });
    },
  },
};
</script>

<style scoped></style>
